import {
    RenderContextProvider,
    Renderer,
    ComponentBlock,
    RenderBlockProps,
    Block,
    LinkBlock,
    TableBlock,
    TableCaptionBlock,
    TableHeaderBlock,
    TableRowBlock,
    TableHeaderCellBlock,
    TableBodyBlock,
    QuoteBlock,
    ImageBlock
} from '@contensis/canvas-react';

import { ContentItem } from 'Templates/Flexible/Content/Content.styled';

import Link, { type LinkProps } from 'Elements/Link';
import ImageCaption, { type ImageCaptionProps } from 'Components/ImageCaption';
import Table, { type TableProps } from 'Components/Table';
import Quote, { type QuoteBlockProps } from 'Components/QuoteBlock';
import PageDivider from 'Components/PageDivider';

import type { FlexibleContentCanvasProps, GraduateStoryCanvasProps } from '.';

type BlockTypes = Block['type'] | '_defaultGroup';
export type CustomBlockRenderers = Partial<
    Record<BlockTypes, (props: RenderBlockProps<Block>) => JSX.Element>
>;

/**
 * Add names of block types that can be handled by the standard Contensis renderer and grouped together
 */
export const defaultGroupedBlockTypes: BlockTypes[] = ['_heading', '_paragraph', '_list', '_image'];

/**
 * Add names of block types that can be handled by the standard Contensis renderer but not grouped together
 */
export const defaultSeparateBlockTypes: BlockTypes[] = ['_table', '_quote', '_divider'];

export interface MappedBlock {
    groupName: BlockTypes;
    elements: JSX.Element[] | CanvasComponentProps;
}

/**
 * Add more page canvas props here
 */
type CanvasComponentProps = FlexibleContentCanvasProps | GraduateStoryCanvasProps;

export const isJSXElement = (item: JSX.Element[] | CanvasComponentProps): item is JSX.Element[] =>
    Array.isArray(item as JSX.Element[]) && item[0].type !== undefined;

const CanvasBasicMap = (
    canvasContent: Block[],
    componentMapper: (data: ComponentBlock) => CanvasComponentProps,
    customBlockRenderers?: CustomBlockRenderers
): MappedBlock[] => {
    let isPreviousGroupedBlockDefault = false;
    const mappedData: MappedBlock[] = [];

    canvasContent.map(item => {
        const isCurrentGroupedBlockDefault = defaultGroupedBlockTypes.includes(item.type);
        const isCurrentSeparateBlockDefault = defaultSeparateBlockTypes.includes(item.type);

        if (isPreviousGroupedBlockDefault && isCurrentGroupedBlockDefault) {
            const lastItem = mappedData.pop();

            if (lastItem) {
                const elements = lastItem.elements as JSX.Element[];
                elements.push(contensisRenderer(item, customBlockRenderers));
                mappedData.push(lastItem);
            }
        } else {
            const newBlock: MappedBlock = {
                groupName: isCurrentGroupedBlockDefault ? '_defaultGroup' : item.type,
                elements:
                    isCurrentGroupedBlockDefault || isCurrentSeparateBlockDefault
                        ? [contensisRenderer(item, customBlockRenderers)]
                        : componentMapper(item as ComponentBlock)
            };
            mappedData.push(newBlock);
        }

        isPreviousGroupedBlockDefault = isCurrentGroupedBlockDefault;
    });

    return mappedData;
};

const contensisRenderer = (
    item: Block,
    customBlockRenderers?: CustomBlockRenderers
): JSX.Element => {
    return (
        <RenderContextProvider
            key={item.id}
            blocks={{
                _link: customLink,
                _image: customImage,
                _table: customTable,
                _quote: customQuote,
                _divider: customDivider,
                ...customBlockRenderers
            }}
        >
            <Renderer data={[item]} />
        </RenderContextProvider>
    );
};

const customLink = (props: RenderBlockProps<LinkBlock>) => {
    const { block } = props;

    const linkProps: LinkProps = {
        href: block.properties?.link?.sys?.uri || '',
        label: block.value as string,
        ariaLabel: block.value as string
    };

    return <Link {...linkProps} />;
};

const customImage = (props: RenderBlockProps<ImageBlock>) => {
    const { block } = props;

    const imageCaptionProps: ImageCaptionProps = {
        image: {
            src: block.value?.asset?.sys?.uri || '',
            alt: block.value?.altText || ''
        },
        caption: block.value?.caption,
        borderRadius: true
    };

    return <ImageCaption {...imageCaptionProps} />;
};

const customTable = (props: RenderBlockProps<TableBlock>) => {
    const { block } = props;

    const tableCaption = block.value && (block.value[0] as TableCaptionBlock);

    const tableHeadingBlock = block.value && (block.value[1] as TableHeaderBlock);
    const tableHeadingCells = tableHeadingBlock?.value as TableRowBlock[];
    const tableHeadings = tableHeadingCells[0].value as TableHeaderCellBlock[];

    const tableBody = block.value && (block.value[2] as TableBodyBlock);
    const tableBodyRows = tableBody?.value as TableRowBlock[];

    const tableProps: TableProps = {
        heading: (tableCaption && (tableCaption.value as string)) || undefined,
        tableHeadings: tableHeadings.map(heading => heading.value as string),
        tableRows: tableBodyRows.map(row =>
            row.value ? row.value.map(cell => cell.value as string) : []
        )
    };

    return (
        <ContentItem>
            <Table {...tableProps} />
        </ContentItem>
    );
};

const customQuote = (props: RenderBlockProps<QuoteBlock>) => {
    const { block } = props;

    const quoteProps: QuoteBlockProps = {
        children: block.value,
        cite: {
            author: block.properties?.source,
            citation: block.properties?.citation,
            url: block.properties?.url
        }
    };

    return (
        <ContentItem>
            <Quote {...quoteProps} />
        </ContentItem>
    );
};

const customDivider = () => {
    return <PageDivider marginBottom={0} />;
};

export default CanvasBasicMap;
