import styled, { css } from 'styled-components';
import type CSS from 'csstype';
import type { NumberWithBreakpoints, StringWithBreakpoints } from 'Theme/types/breakpoints.type';

interface Props {
    $wrap?: CSS.Property.FlexWrap;
    $alignItems?: CSS.Property.AlignItems;
    $justifyContent?: CSS.Property.JustifyContent;
    $direction?: CSS.Property.FlexDirection;
    $gap?: string | StringWithBreakpoints;
    $cols?: number | NumberWithBreakpoints;
}

export const Container = styled.div<Props>`
    ${({ theme, $wrap, $alignItems, $justifyContent, $direction, $gap, $cols }) => css`
        --gap: ${$gap};

        display: flex;
        flex-wrap: ${$wrap};
        align-items: ${$alignItems};
        justify-content: ${$justifyContent};
        flex-direction: ${$direction};
        padding: 0;
        margin: 0;

        ${$gap &&
        css`
            ${typeof $gap === 'string'
                ? css`
                      gap: var(--gap);
                  `
                : Object.keys($gap).map(
                      breakpoint => css`
                          @media ${theme.breakpoints[breakpoint].media} {
                              --gap: ${$gap[breakpoint]};
                              gap: var(--gap);
                          }
                      `
                  )}
        `}

        ${$cols &&
        css`
            --gap: ${$gap || '1.5rem'};
            gap: var(--gap);

            & > * {
                ${typeof $cols === 'number'
                    ? css`
                          --cols: ${$cols};
                          flex-basis: calc(
                              100% / var(--cols) - var(--gap) / var(--cols) * (var(--cols) - 1)
                          );
                      `
                    : Object.keys($cols).map(
                          breakpoint => css`
                              @media ${theme.breakpoints[breakpoint].media} {
                                  --cols: ${$cols[breakpoint]};
                                  flex-basis: calc(
                                      100% / var(--cols) - var(--gap) / var(--cols) *
                                          (var(--cols) - 1)
                                  );
                              }
                          `
                      )}
            }
        `}

        & > li {
            list-style: none;
            padding: 0;
            margin: 0;
        }

        & > li > * {
            height: 100%;
        }
    `}
`;
