import React from 'react';
import type CSS from 'csstype';
import type { NumberWithBreakpoints, StringWithBreakpoints } from 'Theme/types/breakpoints.type';

import { Container } from './FlexBox.styled';

export interface Props {
    wrap?: CSS.Property.FlexWrap;
    alignItems?: CSS.Property.AlignItems;
    justifyContent?: CSS.Property.JustifyContent;
    direction?: CSS.Property.FlexDirection;
    gap?: string | StringWithBreakpoints;
    cols?: number | NumberWithBreakpoints;
    asHtmlElement?: React.ElementType;
    style?: React.CSSProperties;
}

// TODO add tests
// TODO add breakpooint handling

const FlexBox: React.FC<Props> = props => {
    const {
        wrap,
        alignItems,
        justifyContent,
        direction,
        gap,
        cols,
        asHtmlElement = 'div',
        ...rest
    } = props;

    return (
        <Container
            $wrap={wrap}
            $alignItems={alignItems}
            $justifyContent={justifyContent}
            $direction={direction}
            $gap={gap}
            $cols={cols}
            as={asHtmlElement}
            {...rest}
        />
    );
};

export default FlexBox;
