import { Block, ComponentBlock } from '@contensis/canvas-react';
import htmlParser from 'html-react-parser';
import dynamic from 'next/dynamic';

import defaultProfileImage from 'Public/images/cards/profile/default.png';

import cmsParser from 'Common/util/cmsParser';
import { sanitizePhoneNumber } from 'Common/util/string';

import { AccordionHeading } from 'Templates/Flexible/Landing/Landing.styled';

import type { ImageCaptionProps } from 'Components/ImageCaption';
import type { ImageAndTextProps } from 'Components/TextBlock/ImageAndText';
import type { VideoProps } from 'Components/Video';
import type { AttentionBoxProps } from 'Components/AttentionBox';
import type { AccordionProps } from 'Components/Accordion';
import type { ListProps } from 'Components/List';
import type { ProfileCardProps } from 'Components/Card/Profile';
import type { IframeGeckoProps } from 'Components/IframeGecko';
import type { ContactInfoSupportBlockProps } from 'Components/SupportBlock/ContactInfo';
import type { UnibuddyMainProps } from 'Components/Unibuddy';
import type { UnibuddyCarouselProps } from 'Components/Unibuddy/Carousel';

import type { LinkCardsProps } from 'Modules/Cards/Link';
import type { MiniCardsProps } from 'Modules/Cards/Mini';
import type { BrandBannerProps } from 'Modules/Banner/Brand';
import type { PromoPointTefAwardProps } from 'Modules/PromoPoint/TefAward';

import CanvasDataMapper, { type MappedBlock } from './CanvasDataMapper';

export interface FlexibleContentCanvasProps {
    image?: {
        imageCaption: ImageCaptionProps;
        columnWidth: number;
    };
    textImageTwoColumns?: ImageAndTextProps;
    video?: {
        heading: string;
        embed: VideoProps;
    };
    attentionBox?: AttentionBoxProps;
    accordionCollectionCanvas?: AccordionProps[];
    listTwoColumns?: {
        listOneHeading: string;
        listOne: ListProps;
        listTwoHeading: string;
        listTwo: ListProps;
    };
    promoCards?: LinkCardsProps;
    meetTheTeam?: {
        heading?: string;
        cards: MiniCardsProps;
    };
    brandBanner?: BrandBannerProps;
    tefAward?: PromoPointTefAwardProps;
    flexibleAcademicStaffProfile?: ProfileCardProps & { heading: string };
    geckoForm?: IframeGeckoProps;
    contactInformation?: { contacts: ContactInfoSupportBlockProps[]; heading: string };
    unibuddyMain?: UnibuddyMainProps;
    unibuddyCarousel?: UnibuddyCarouselProps;
    anchor?: string;
}

const FlexibleContentPage = (canvasContent: Block[]): MappedBlock[] => {
    return CanvasDataMapper(canvasContent, ComponentMapper);
};

const ComponentMapper = (data: ComponentBlock): FlexibleContentCanvasProps => {
    switch (data.properties?.component) {
        case 'imageCaption':
            return {
                image: {
                    imageCaption: {
                        image: cmsParser.image(data.value.image, '/cms'),
                        caption: data.value.image.caption
                    },
                    columnWidth: parseInt(data.value.columnWidth)
                }
            };
        case 'textImageTwoColumns':
            return {
                textImageTwoColumns: {
                    description: htmlParser(data.value.text),
                    imageCaption: {
                        image: cmsParser.image(data.value.imageCaption, '/cms'),
                        caption: data.value.imageCaption.caption
                    },
                    imageLeftOfDescription:
                        data.value.layout === 'Image left, text right' ? true : false,
                    imageColumnWidth: data.value.imageColumnWidth
                        ? parseInt(data.value.imageColumnWidth)
                        : 6
                }
            };
        case 'youTubeVideo':
            return {
                video: {
                    heading: data.value.title,
                    embed: cmsParser.video(data.value)
                }
            };
        case 'attentionBox':
            return {
                attentionBox: {
                    icon: data.value.iconName
                        ? dynamic(() =>
                              import('@solent-university/solent-icons').then(
                                  icons => icons[data.value.iconName.name]
                              )
                          )
                        : undefined,
                    text: data.value.text
                }
            };
        case 'accordionCollectionCanvas':
            return {
                accordionCollectionCanvas: data.value.accordion.map((item, index) => ({
                    id: index,
                    heading: <AccordionHeading>{item.title}</AccordionHeading>,
                    headingType: 'h3',
                    button: {
                        ariaLabel: `View ${item.title}`
                    },
                    children: htmlParser(item.content),
                    variant: 'tertiary',
                    isBackgroundSameColour: true
                }))
            };
        case 'listTwoColumns':
            return {
                listTwoColumns: {
                    listOneHeading: data.value.leftColumnList.label,
                    listOne: {
                        items: data.value.leftColumnList.item.map((item, index) => ({
                            id: index,
                            text: item
                        }))
                    },
                    listTwoHeading: data.value.rightColumnList.label,
                    listTwo: {
                        items: data.value.rightColumnList.item.map((item, index) => ({
                            id: index,
                            text: item
                        }))
                    }
                }
            };
        case 'squareGridPromoCardsWrapper':
            return {
                promoCards: {
                    heading: data.value.heading && data.value.heading,
                    cards: data.value.squareGridPromoCard.map((card, index) => ({
                        link: cmsParser.link(card),
                        id: `link-card-${index}`,
                        backgroundColor: cmsParser.getThemeColour(card.backgroundColor),
                        textColor: cmsParser.getThemeColour(card.textColor)
                    }))
                }
            };
        case 'meetTheTeam':
            return {
                meetTheTeam: {
                    heading: data.value.heading && data.value.heading,
                    cards: {
                        items: data.value.courseTeamMember
                            .filter(item => item.profile?.staffMember && item.profile.sys?.uri)
                            .map(({ profile, role }) => {
                                const fullName = `${
                                    profile.staffMember.person.preferredName ||
                                    profile.staffMember.person.forename
                                } ${profile.staffMember.person.surname}`;

                                return {
                                    id: profile.sys.id,
                                    heading: fullName,
                                    furtherDetails: role || profile.staffMember.jobTitle,
                                    image: profile.staffMember.person.picture
                                        ? cmsParser.image(profile.staffMember.person.picture)
                                        : { src: defaultProfileImage },
                                    link: {
                                        href: profile.sys.uri,
                                        ariaLabel: `View the profile of ${fullName}`
                                    }
                                };
                            })
                    }
                }
            };
        case 'bannerBrand':
            return {
                brandBanner: {
                    heading: data.value.heading,
                    text: data.value.description,
                    link: {
                        ...cmsParser.linkButton(data.value.buttonCTA),
                        variant: data.value.buttonVariant ? data.value.buttonVariant : 'primary'
                    },
                    backgroundColor: cmsParser.getThemeColour(data.value.backgroundColor),
                    textColor: cmsParser.getThemeColour(data.value.textColor)
                }
            };
        case 'tefAward':
            return {
                tefAward: {
                    heading: data.value.promoPointHeading,
                    text: data.value.promoPointDescription,
                    link: data.value.promoPointButton && {
                        ...cmsParser.linkButton(data.value.promoPointButton)
                    },
                    image: cmsParser.image(data.value.tefImage)
                }
            };
        case 'flexibleAcademicStaffProfile': {
            const profile = data.value.academicStaffProfile.staffMember;
            return {
                flexibleAcademicStaffProfile: {
                    heading: data.value.heading,
                    firstName: profile.person.forename,
                    lastName: profile.person.surname,
                    jobTitle: profile.jobTitle,
                    info: data.value.academicStaffProfile.department.name,
                    email:
                        profile.person.email && !profile.person.hideEmail
                            ? {
                                  label: profile.person.email,
                                  ariaLabel: `Email ${profile.person.email}`,
                                  href: `mailto:${profile.person.email}`
                              }
                            : undefined,
                    phone: profile.person.phoneNumber
                        ? {
                              label: profile.person.phoneNumber,
                              ariaLabel: `Call ${profile.person.phoneNumber}`,
                              href: `tel:${sanitizePhoneNumber(profile.person.phoneNumber)}`
                          }
                        : undefined,
                    profileLink: cmsParser.link({
                        ...data.value.academicStaffProfile,
                        label: `View profile`,
                        ariaLabel: `Click to view profile`
                    }),
                    image: profile.person.picture
                        ? {
                              ...cmsParser.image(profile.person.picture)
                          }
                        : undefined
                }
            };
        }
        case 'geckoFormCanvas':
            return {
                geckoForm: {
                    id: data.value.form.geckoScriptId,
                    url: data.value.form.url,
                    heading: data.value.form.header,
                    subHeading: data.value.form.subHeading
                }
            };
        case 'contactsCanvas':
            return {
                contactInformation: {
                    heading: data.value.contacts.heading,
                    contacts: data.value.contacts.contacts.map(contact => ({
                        heading: contact.heading,
                        headingType: 'h3',
                        icons: contact.useIcons,
                        contacts: [
                            {
                                information: contact.informationText
                                    ? contact.informationText
                                    : undefined,
                                phone: contact.phone.label
                                    ? cmsParser.link(contact.phone)
                                    : undefined,
                                email: contact.email.label
                                    ? cmsParser.link(contact.email)
                                    : undefined,
                                url: contact.url.label ? cmsParser.link(contact.url) : undefined
                            }
                        ]
                    }))
                }
            };
        case 'uniBuddyMain':
            return {
                unibuddyMain: {
                    iframe: {
                        src: data.value.iframeSrc
                    },
                    heading: data.value.heading,
                    id: `flexible-unibuddy-main`
                }
            };
        case 'uniBuddyCarousel':
            return {
                unibuddyCarousel: {
                    heading: data.value.heading,
                    id: `flexible-unibuddy-carousel`
                }
            };
        case 'anchorLink':
            return { anchor: data.value.anchor };
        default:
            return {};
    }
};

export default FlexibleContentPage;
