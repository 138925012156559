import styled, { css } from 'styled-components';

export const ContentItem = styled.div`
    ${({ theme }) => css`
        position: relative;
        margin-top: 2rem;

        @media ${theme.breakpoints.medium.media} {
            margin-top: 3.5rem;
        }
    `}
`;

export const AnchorContainer = styled.div`
    position: relative;
`;

export const Anchor = styled.span`
    position: absolute;
    top: 0;
`;
